import React from 'react'
import ExamApp from './_ExamApp'
import useLocations from './state/useLocations'
import useEffectOnce from '../hooks/useEffectOnce'
import { useShuffledAnswersOnce } from './hooks/useQuestionAnswers'
import { getExamLocations } from './utils/locations'

const ExamAppRoot = ({ urlPrefix, ...rest }) => {
  const { exam, examQuestions, answers } = rest

  const [locations, setLocations] = useLocations()

  useShuffledAnswersOnce(answers) // shuffle answers once

  useEffectOnce(() => {
    const paths = getExamLocations(urlPrefix, exam, examQuestions)

    setLocations(paths)
  })

  if (locations.length === 0) {
    return null
  }

  return <ExamApp {...rest} />
}

export default ExamAppRoot
