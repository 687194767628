import { useAllResponded } from '../state/useAnswers'
import { questionAnswersWorker } from './useQuestionAnswers'
import { TO_PASS_EXAM_PERCENTAGE } from '../utils/constants'

/**
 * Results hook
 */
const useResults = (allTheoryQuestions, allExamQuestions, allAnswers) => {
  const [allRespondedAnswers] = useAllResponded()

  let countCorrectTheoryQuestions = 0
  let countCorrectExamQuestions = 0

  const countTotalTheoryQuestions = allTheoryQuestions.length
  const countTotalExamQuestions = allExamQuestions.length

  const isQuestionCorrect = (question) => {
    const qResponded =
      allRespondedAnswers[question.id] === undefined ? [] : allRespondedAnswers[question.id]
    const qAnswers = allAnswers.filter((x) => x.question_id === question.id)

    const { isCorrect } = questionAnswersWorker(qResponded, qAnswers)

    return isCorrect
  }

  allTheoryQuestions.forEach((question) => {
    if (isQuestionCorrect(question)) {
      countCorrectTheoryQuestions++
    }
  })

  allExamQuestions.forEach((question) => {
    if (isQuestionCorrect(question)) {
      countCorrectExamQuestions++
    }
  })

  const isExamPassed =
    countCorrectExamQuestions / countTotalExamQuestions >= TO_PASS_EXAM_PERCENTAGE // 80%. 4 out of 5 questions

  return {
    countCorrectTheoryQuestions,
    countCorrectExamQuestions,
    countTotalTheoryQuestions,
    countTotalExamQuestions,
    isExamPassed,
  }
}

export default useResults
