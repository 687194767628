import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { NakedLayout, SplashPage } from '../../../components/Layout'
import ExamApp from '../../../app/ExamApp'
import api from '../../../apis'

const ExamPage = ({ examId }) => {
  const [responseCode, setResponseCode] = useState(null)
  const [responseData, setResponseData] = useState(false)

  useEffect(() => {
    api.exam
      .getExamData(examId)
      .then((response) => {
        setResponseCode(200)
        setResponseData(response.data)
      })
      .catch((error) => {
        setResponseCode(error.response.status)
        setResponseData(error.response.data)
        return Promise.resolve(error)
      })
  }, [examId])

  if (responseData === false || responseCode === null) {
    return <SplashPage />
  }

  if (responseCode === 404 || responseCode === 403) {
    return <SplashPage errorMessage={responseData.message} />
  }

  return (
    <NakedLayout useDarkMode>
      <ExamApp urlPrefix={'/app/exams/'} {...responseData} />
    </NakedLayout>
  )
}

ExamPage.propTypes = {
  examId: PropTypes.string,
}

export default ExamPage
