import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CircleButton from '../../../components/Button/CircleButton'

const HeaderCloseButton = () => (
  <CircleButton className={'mr-10'} onClick={null}>
    <FontAwesomeIcon
      className={'text-lg text-gray-600 dark:text-gray-200'}
      icon={['fal', 'times']}
    />
  </CircleButton>
)

HeaderCloseButton.propTypes = {
  //
}

export default HeaderCloseButton
