import React from 'react'
import { Router } from '@reach/router'
import { useTranslation } from 'react-i18next'
import useSound from 'use-sound'

// Utils and hooks
import Timers from '../utils/timer'
import Confetti from '../utils/confetti'
import { TYPES } from '../utils/locations'
import useAppStatus, { STATUSES } from '../state/useAppStatus'
import useLocator from '../hooks/useLocator'
import useEffectOnce from '../../hooks/useEffectOnce'
import endOfTaskMp3 from '../static/end.mp3'
import useResults from '../hooks/useResults'

// Routes
import Lesson from '../routes/Lesson'
import Theory from '../routes/Theory'
import Route from '../routes/Route'
import Question from '../routes/Question'
import Exam from '../routes/Exam'
import Results from '../routes/Results'

const LessonApp = ({ subject, lesson, theories, questions, answers, exam, examQuestions }) => {
  const { t } = useTranslation('apps')

  const { goFirstLocation, changeVisibility, startHistoryListener } = useLocator()
  const [appStatus, setAppStatus] = useAppStatus()

  const { isExamPassed } = useResults(questions, examQuestions, answers)

  const [playPassedSound, { stop: stopPassedSound }] = useSound(endOfTaskMp3)

  useEffectOnce(() => {
    goFirstLocation().then(() => {
      setAppStatus(STATUSES.LOADED)
    })

    const removeListener = startHistoryListener()

    return () => {
      removeListener()
      stopPassedSound()
      Confetti.reset()
    }
  })

  if (appStatus === STATUSES.LOADING) {
    return null
  }

  const isLessonEnded = appStatus === STATUSES.ENDED

  const TYPES_EXAM_ONLY = [TYPES.EXAM_QUESTION]
  const TYPES_LEARNING_ONLY = [TYPES.LESSON, TYPES.THEORY, TYPES.QUESTION, TYPES.EXAM]

  return (
    <Router>
      <Lesson path="/" lesson={lesson} subject={subject} />

      <Route
        path="theory/:theoryId"
        content={({ theoryId }) => (
          <Theory
            path="theory/:theoryId"
            theory={theories.find((x) => x.id === theoryId)}
            lesson={lesson}
          />
        )}
      />

      <Route
        path="question/:questionId"
        content={({ questionId }) => (
          <Question
            question={questions.find((x) => x.id === questionId)}
            answers={answers.filter((x) => x.question_id === questionId)}
            title={
              t('header.question') +
              ' #' +
              ('0' + (questions.map((x) => x.id).indexOf(questionId) + 1)).slice(-2)
            }
            subtitle={lesson.name}
            isExamQuestion={false}
            isExamEnded={false}
            isLastQuestion={questions[questions.length - 1].id === questionId}
          />
        )}
      />

      <Route
        path="exam/:examId"
        content={() => (
          <Exam
            exam={exam}
            onStartExam={() => changeVisibility(TYPES_EXAM_ONLY, TYPES_LEARNING_ONLY)}
            isIndependentFromTheory={false}
            countQuestions={examQuestions.length}
            isEnded={isLessonEnded}
          />
        )}
      />

      <Route
        path="exam/:examId/question/:questionId"
        content={(params) => (
          <Question
            question={examQuestions.find((x) => x.id === params.questionId)}
            title={
              t('header.question') +
              ' #' +
              ('0' + (examQuestions.map((x) => x.id).indexOf(params.questionId) + 1)).slice(-2)
            }
            subtitle={lesson.name}
            isExamQuestion={true}
            isExamEnded={isLessonEnded}
            isLastQuestion={examQuestions[examQuestions.length - 1].id === params.questionId}
            onLastQuestionContinueClick={() => {
              if (isLessonEnded === false) {
                changeVisibility([...TYPES_EXAM_ONLY, ...TYPES_LEARNING_ONLY], [])
                Timers.setIsTimeTrackingEnabled(false)
                Timers.stopAllTimers()
                setAppStatus(STATUSES.ENDED)

                if (isExamPassed) {
                  playPassedSound()
                  Confetti.resultsFireworks()
                }
              }
            }}
            answers={answers.filter((x) => x.question_id === params.questionId)}
          />
        )}
      />

      <Results
        path={'results'}
        questions={questions}
        isExamOnly={false}
        examQuestions={examQuestions}
        title={lesson.name}
        subtitle={subject.name}
        answers={answers}
      />
    </Router>
  )
}

export default LessonApp
