import useLocations from '../state/useLocations'
import { useLocation } from '@reach/router'
import { navigate } from '@reach/router'
import { navigate as navigateGatsby } from 'gatsby'
import { clone } from '../../utils/array'
import Timers from '../utils/timer'
import { globalHistory } from '@reach/router'
import ROUTES from '../../config/routes'
import AudioMapList from '../utils/audio'

/**
 * Hook wrapping 2 global state hooks
 */
const useLocator = () => {
  const [allLocations, setAllLocations] = useLocations()

  const currentLocationData = useLocation()
  const currentLocation = currentLocationData.pathname

  const setLocation = navigate
  const setGatsbyLocation = navigateGatsby

  const getCurrentLocationIndex = () => {
    return allLocations.map((e) => e.href).indexOf(currentLocation)
  }

  const isCurrentLocationFirst = () => {
    return getCurrentLocationIndex() === 0
  }

  const getCurrentLocationData = () => {
    return allLocations[getCurrentLocationIndex()]
  }

  const isCurrentLocationLast = () => {
    return getCurrentLocationIndex() === allLocations.length - 1
  }

  const getNextLocation = () => {
    const locationIndex = getCurrentLocationIndex()

    return allLocations[locationIndex + 1].href
  }

  const getPrevLocation = () => {
    const locationIndex = getCurrentLocationIndex()

    return allLocations[locationIndex - 1].href
  }

  const isLocationDisabled = (locationIndex) => {
    let location = allLocations[locationIndex]

    return location.disabled === true
  }

  const enableLocation = (locationIndex) => {
    const clonedAllLocations = clone(allLocations)
    clonedAllLocations[locationIndex].disabled = false
    setAllLocations(clonedAllLocations)
  }

  const changeVisibility = (visibleTypes, invisibleTypes) => {
    const clonedAllLocations = clone(allLocations)

    for (const [key, loc] of Object.entries(clonedAllLocations)) {
      if (visibleTypes.includes(loc.type)) {
        loc.visible = true
      } else if (invisibleTypes.includes(loc.type)) {
        loc.visible = false
      }

      clonedAllLocations[key] = loc
    }

    setAllLocations(clonedAllLocations)
  }

  const goForward = () => {
    if (isCurrentLocationLast()) {
      return redirectHome()
    }

    const nextLocationIndex = getCurrentLocationIndex() + 1

    if (isLocationDisabled(nextLocationIndex)) {
      enableLocation(nextLocationIndex)
    }

    return goLocation(getNextLocation())
  }

  const goBack = () => {
    if (isCurrentLocationFirst()) {
      return window.confirm('Are you sure you want to leave this page?') && redirectHome()
    }

    return goLocation(getPrevLocation())
  }

  const goFirstLocation = () => {
    const location = allLocations[0]

    return goLocation(location.href)
  }

  const redirectHome = () => {
    return setGatsbyLocation(ROUTES.home)
  }

  const timeTrack = (prevLocation, nextLocation) => {
    if (Timers.isTimeTrackingEnabled === false) {
      return
    }

    const currentTimer = Timers.getTimer(prevLocation)

    if (currentTimer.isRunning) {
      currentTimer.stop()
    }

    const newTimer = Timers.getTimer(nextLocation)
    newTimer.start()
  }

  const goLocation = (location) => {
    timeTrack(currentLocation, location)
    AudioMapList.stopAllSounds()

    return setLocation(location)
  }

  const startHistoryListener = () => {
    let prevLocationHref = null

    return globalHistory.listen(({ action, location }) => {
      if (action === 'PUSH') {
        prevLocationHref = location.pathname

        return
      }

      if (action === 'POP') {
        const newLocationHref = location.pathname

        if (prevLocationHref === null) {
          return redirectHome()
        }

        timeTrack(prevLocationHref, newLocationHref)

        prevLocationHref = newLocationHref
      }
    })
  }

  const getSecondsSpentOnLocation = (location) => {
    const timer = Timers.getTimer(location)

    return timer.getTimeInSeconds()
  }

  return {
    allLocations,
    currentLocation,
    getCurrentLocationData,
    goLocation,
    getCurrentLocationIndex,
    isCurrentLocationFirst,
    isCurrentLocationLast,
    getNextLocation,
    getPrevLocation,
    isLocationDisabled,
    enableLocation,
    changeVisibility,
    goForward,
    goBack,
    goFirstLocation,
    redirectHome,
    startHistoryListener,
    getSecondsSpentOnLocation,
  }
}

export default useLocator
