import React from 'react'
import PropTypes from 'prop-types'

const Wrapper = ({ children }) => {
  return <div className={'flex flex-col justify-between lg:min-h-screen'}>{children}</div>
}

Wrapper.propTypes = {
  children: PropTypes.any,
}

export default Wrapper
