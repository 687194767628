import React from 'react'
import { useTranslation } from 'react-i18next'
import ResultsTableRow from './ResultsTableRow'
import useQuestionAnswers from '../../hooks/useQuestionAnswers'

const ResultsQuestionTableRow = ({ questionId, answers, ...rest }) => {
  const questionAnswers = answers.filter((x) => x.question_id === questionId)
  const { isCorrect, isWrong } = useQuestionAnswers(questionId, questionAnswers)

  return <ResultsTableRow {...rest} isWrong={isWrong} isCorrect={isCorrect} />
}

const ResultsTable = ({
  answers,
  locations,
  getSecondsSpentOnLocation,
  children,
  isTheory,
  headerContent,
}) => {
  const { t } = useTranslation('apps')

  return (
    <table>
      <tbody>
        <tr>
          <td colSpan={2}>{headerContent}</td>

          <td>
            <div className={'text-xl font-bold font-bitter text-right'}>
              {t('results.time-spent')}
            </div>
          </td>
        </tr>

        {locations.map((location) => {
          const { short, name, questionId, questionOrder, href } = location

          const rowName =
            questionId !== undefined && questionOrder !== undefined
              ? t('header.question') + ' #' + ('0' + questionOrder).slice(-2)
              : name

          if (questionId && isTheory === false) {
            return (
              <ResultsQuestionTableRow
                key={href}
                short={short}
                name={rowName}
                answers={answers}
                questionId={questionId}
                secondsSpent={getSecondsSpentOnLocation(href)}
              />
            )
          }

          return (
            <ResultsTableRow
              key={href}
              short={short}
              name={rowName}
              isWrong={false}
              isCorrect={false}
              secondsSpent={getSecondsSpentOnLocation(href)}
            />
          )
        })}

        <tr>
          <td colSpan={3}>{children}</td>
        </tr>
      </tbody>
    </table>
  )
}

export default ResultsTable
