import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Wrapper from '../components/Wrapper'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Body from '../components/Body'
import HtmlContent from '../components/HtmlContent'
import useLocator from '../hooks/useLocator'

const Theory = ({ theory, lesson }) => {
  const { t } = useTranslation('apps')

  const { goBack, goForward } = useLocator()

  return (
    <Wrapper>
      <Header title={theory.name} subtitle={lesson.name} goBack={goBack} />

      <Body>
        <HtmlContent id={theory.id} audibleAutoplayFirst html={theory.content} />
      </Body>

      <Footer
        right={
          <button
            className={'btn btn-lg btn-primary chameleon w-full sm:w-auto'}
            onClick={goForward}
          >
            {t('buttons.continue')}
          </button>
        }
      />
    </Wrapper>
  )
}

Theory.propTypes = {
  theory: PropTypes.object.isRequired,
  lesson: PropTypes.object.isRequired,
}

export default Theory
