import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const Footer = ({ isError, isSuccess, left, right }) => (
  <div
    className={classnames(
      'flex flex-col justify-center pt-3 pb-6 sm:py-6 lg:py-0 lg:h-44 px-3 lg:border-t',
      {
        'border-gray-200 dark:border-gray-800': isError !== true && isSuccess !== true,
        'bg-red-200 border-red-200 dark:bg-red-600 dark:border-red-600': isError,
        'bg-lime-200 border-lime-200 dark:bg-lime-600 dark:border-lime-600': isSuccess,
      },
    )}
  >
    <div className={'max-w-7xl mx-auto w-full'}>
      <div className={'flex flex-col sm:flex-row justify-between'}>
        <div className={'flex items-center'}>{typeof left === 'function' ? left() : left}</div>
        <div className={'flex items-center'}>{typeof right === 'function' ? right() : right}</div>
      </div>
    </div>
  </div>
)

Footer.propTypes = {
  left: PropTypes.any,
  right: PropTypes.any,
  isError: PropTypes.bool,
  isSuccess: PropTypes.bool,
}

export default Footer
