import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CircleButton from '../../../components/Button/CircleButton'

const HeaderGoBackButton = ({ goBack }) => (
  <CircleButton className={'mr-5 lg:mr-10'} onClick={goBack}>
    <FontAwesomeIcon
      className={'text-gray-600 dark:text-gray-200'}
      icon={['fal', 'chevron-left']}
    />
  </CircleButton>
)

HeaderGoBackButton.propTypes = {
  goBack: PropTypes.func,
}

export default HeaderGoBackButton
