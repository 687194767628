import React from 'react'
import PropTypes from 'prop-types'

const ProgressLine = ({ width }) => {
  const style = {
    top: '12px',
    left: '1px', // hide behind steps
    right: '1px', // hide behind steps
    height: '3px',
  }

  return (
    <div className="absolute flex overflow-hidden bg-gray-200 dark:bg-gray-600" style={style}>
      <div
        className="flex overflow-hidden flex-col justify-center bg-black dark:bg-white transition-width duration-700"
        aria-label="Progress"
        role="progressbar"
        style={{ width: width + '%' }}
        aria-valuenow={width}
        aria-valuemin={0}
        aria-valuemax={100}
      />
    </div>
  )
}

ProgressLine.propTypes = {
  width: PropTypes.number,
}

export default ProgressLine
