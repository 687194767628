import React, { useState, useEffect, useRef } from 'react'
import ProgressStep from './ProgressStep'
import ProgressLine from './ProgressLine'
import useLocator from '../../hooks/useLocator'
import { TYPES } from '../../utils/locations'

const Progress = () => {
  const containerRef = useRef()

  const { currentLocation, allLocations, goLocation } = useLocator()

  const [width, setWidth] = useState(0)

  useEffect(() => {
    const parent = containerRef.current
    const child = document.getElementsByClassName('progress-step-active')[0]

    const width = (child.offsetLeft / parent.offsetWidth) * 100
    setWidth(width)
  }, [currentLocation])

  let options = []

  for (const [key, loc] of Object.entries(allLocations)) {
    options.push(
      <ProgressStep
        key={`step-${key}`}
        isActive={loc.href === currentLocation}
        isDisabled={loc.disabled}
        isVisible={loc.visible}
        label={loc.short}
        isLast={loc.type === TYPES.RESULT}
        onClick={(e) => goLocation(loc.href)}
      />,
    )
  }

  return (
    <div className={'relative w-full'} style={{ height: '28px' }}>
      <ProgressLine width={width} />

      <div
        ref={containerRef}
        className={'absolute flex items-center justify-between left-0 right-0'}
      >
        {options}
      </div>
    </div>
  )
}

Progress.propTypes = {
  //
}

export default Progress
