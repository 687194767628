// https://stackoverflow.com/questions/29971898/how-to-create-an-accurate-timer-in-javascript

class TimerJsConstructor {
  constructor() {
    this.isRunning = false
    this.startTime = 0
    this.overallTime = 0
  }

  _getTimeElapsedSinceLastStart() {
    if (!this.startTime) {
      return 0
    }

    return Date.now() - this.startTime
  }

  start() {
    if (this.isRunning) {
      return console.error('Timer is already running')
    }

    this.isRunning = true

    this.startTime = Date.now()
  }

  stop() {
    if (!this.isRunning) {
      return console.error('Timer is already stopped')
    }

    this.isRunning = false

    this.overallTime = this.overallTime + this._getTimeElapsedSinceLastStart()
  }

  reset() {
    this.overallTime = 0

    if (this.isRunning) {
      this.startTime = Date.now()
      return
    }

    this.startTime = 0
  }

  getTime() {
    if (!this.startTime) {
      return 0
    }

    if (this.isRunning) {
      return this.overallTime + this._getTimeElapsedSinceLastStart()
    }

    return this.overallTime
  }

  getTimeInSeconds() {
    return Math.round(this.getTime() / 1000)
  }
}

class TimerList {
  constructor() {
    this.timers = []
    this.isTimeTrackingEnabled = true // by default true
  }

  addTimer(id) {
    this.timers[id] = new TimerJsConstructor()
  }

  getTimer(id) {
    if (this.timers[id] === undefined) {
      this.addTimer(id)
    }

    return this.timers[id]
  }

  setIsTimeTrackingEnabled(enabled) {
    this.isTimeTrackingEnabled = enabled
  }

  stopAllTimers() {
    for (var key in this.timers) {
      const timer = this.timers[key]

      if (timer.isRunning === true) {
        timer.stop()
      }
    }
  }
}

const Timers = new TimerList()

export default Timers

export { TimerJsConstructor }
