import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const Body = ({ children, mediumWide }) => {
  return (
    <div className={'py-6 lg:py-24 px-3'}>
      <div
        className={classnames('mx-auto', {
          'max-w-7xl': mediumWide !== true,
          'max-w-4xl': mediumWide === true,
        })}
      >
        {children}
      </div>
    </div>
  )
}

Body.protoTypes = {
  children: PropTypes.any,
  mediumWide: PropTypes.bool,
}

export default Body
