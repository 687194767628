import React from 'react'
import PropTypes from 'prop-types'
import HeaderGoBackButton from './HeaderGoBackButton'
import HeaderCloseBackButton from './HeaderCloseButton'
import HeaderTitle from './HeaderTitle'
import HeaderSettingsMenu from './HeaderSettingsMenu'
import Progress from '../Progress'
import Timer from '../Timer'

const Header = ({
  showTitleSubtitle,
  showGoBackButton,
  showProgressBar,
  showTimer,
  showCloseButton,
  goBack,
  title,
  subtitle,
  titleSubtitleReversed,
}) => {
  return (
    <div className={'h-40 lg:h-24 lg:border-b border-gray-100 dark:border-gray-800 relative px-3'}>
      <div
        className={
          'flex justify-around lg:justify-between flex-col lg:flex-row max-w-7xl mx-auto w-full h-full'
        }
      >
        <div className={'flex items-center'}>
          {showGoBackButton && <HeaderGoBackButton goBack={goBack} />}

          {showCloseButton && <HeaderCloseBackButton />}

          {showTitleSubtitle && (
            <HeaderTitle
              title={title}
              subtitle={subtitle}
              titleSubtitleReversed={titleSubtitleReversed}
            />
          )}
        </div>

        <div className={'flex items-center lg:grow w-full lg:w-max mx-auto lg:mx-0 max-w-2xl'}>
          {showProgressBar && <Progress />}
          {showTimer && <Timer classNames={'text-xl ml-8'} />}
        </div>

        <div className={'absolute right-0 top-0 bottom-0'}>
          <div className={'mt-1 mr-1 sm:mt-6 sm:mr-3 lg:mt-28 2xl:mt-6'}>
            <HeaderSettingsMenu />
          </div>
        </div>
      </div>
    </div>
  )
}

Header.propTypes = {
  goBack: PropTypes.func,
  showGoBackButton: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  showProgressBar: PropTypes.bool,
  showTimer: PropTypes.bool,

  title: PropTypes.any,
  subtitle: PropTypes.any,
  titleSubtitleReversed: PropTypes.bool,
  showTitleSubtitle: PropTypes.bool,
}

Header.defaultProps = {
  showGoBackButton: true,
  showCloseButton: false,
  titleSubtitleReversed: false,
  showTitleSubtitle: true,
  showProgressBar: true,
  showTimer: false,
}

export default Header
