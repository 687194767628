import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Wrapper from '../components/Wrapper'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Body from '../components/Body'
import HtmlContent from '../components/HtmlContent'
import useLocator from '../hooks/useLocator'
import Emoji, { EmojiList } from '../../components/Emoji'

const Exam = ({ exam, onStartExam, isEnded, countQuestions, isIndependentFromTheory }) => {
  const { t } = useTranslation('apps')

  const { goForward, goBack } = useLocator()

  const onStartButtonClick = () => {
    onStartExam()
    goForward()
  }

  const title = isIndependentFromTheory ? t('examination.header') : t('progress-test.header')

  const buttonLabel = isIndependentFromTheory
    ? t('buttons.start-exam')
    : t('buttons.start-progress-test')

  const itemsOptions = {
    returnObjects: true,
    count: countQuestions,
  }

  const items = isIndependentFromTheory
    ? t('examination.list', itemsOptions)
    : t('progress-test.list', itemsOptions)

  return (
    <Wrapper>
      <Header title={title} subtitle={exam.name} goBack={goBack} />

      <Body>
        <HtmlContent id={exam.id}>
          <h1>
            <Emoji classNames={'text-5xl mr-3'} emoji={EmojiList.timerClock} />

            {title}
          </h1>

          <ul>
            {items.map((i, key) => (
              <li key={key}>{i}</li>
            ))}
          </ul>
        </HtmlContent>
      </Body>

      <Footer
        right={() => {
          if (isEnded === true) {
            return (
              <button
                className={'btn btn-lg btn-primary chameleon w-full sm:w-auto'}
                onClick={goForward}
              >
                {t('buttons.continue')}
              </button>
            )
          }

          return (
            <button
              className={'btn btn-lg btn-primary chameleon w-full sm:w-auto'}
              onClick={onStartButtonClick}
            >
              {buttonLabel}
            </button>
          )
        }}
      />
    </Wrapper>
  )
}

Exam.propTypes = {
  exam: PropTypes.object.isRequired,
  onStartExam: PropTypes.func.isRequired,
  isEnded: PropTypes.bool.isRequired,
  countQuestions: PropTypes.number.isRequired,
  isIndependentFromTheory: PropTypes.bool.isRequired,
}

export default Exam
