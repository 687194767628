import React from 'react'
import LessonApp from './_LessonApp'
import useLocations from './state/useLocations'
import useEffectOnce from '../hooks/useEffectOnce'
import { useShuffledAnswersOnce } from './hooks/useQuestionAnswers'
import { getLessonLocations } from './utils/locations'

const LessonAppRoot = ({ urlPrefix, ...rest }) => {
  const { lesson, theories, questions, exam, examQuestions, answers } = rest

  const [locations, setLocations] = useLocations()

  useShuffledAnswersOnce(answers) // shuffle answers once

  useEffectOnce(() => {
    const paths = getLessonLocations(urlPrefix, lesson, theories, questions, exam, examQuestions)

    setLocations(paths)
  })

  if (locations.length === 0) {
    return null
  }

  return <LessonApp {...rest} />
}

export default LessonAppRoot
