import React from 'react'
import PropTypes from 'prop-types'
import { CenteredLayout } from '../../../components/Layout'
import QuestionApp from '../../../app/QuestionApp'

const GenerativeQuestionPage = ({ questionId }) => {
  return (
    <CenteredLayout rootProps={{ useDarkMode: true }} classNames={'w-full max-w-xl mx-auto'}>
      <QuestionApp showDarkModeSwitch questionId={questionId} />
    </CenteredLayout>
  )
}

GenerativeQuestionPage.propTypes = {
  questionId: PropTypes.string,
}

export default GenerativeQuestionPage
