import { britishPounds } from '../components/Money'
import { REAL_LIFE_LESSON_PRICE_HOUR, REAL_LIFE_LESSON_TIME_SECONDS } from './constants'

export const getSecondsSaved = (timeSpentSeconds) => {
  return REAL_LIFE_LESSON_TIME_SECONDS - timeSpentSeconds
}

export const getMinutesSaved = (timeSpentSeconds) => {
  const secondsSaved = getSecondsSaved(timeSpentSeconds)

  return Math.round(secondsSaved / 60)
}

export const getMoneySaved = (timeSpentSeconds) => {
  const realLifePricePerHour = britishPounds(REAL_LIFE_LESSON_PRICE_HOUR)
  const realLifeHours = REAL_LIFE_LESSON_TIME_SECONDS / 60 / 60
  const realLifeCost = realLifePricePerHour.multiply(realLifeHours)

  const academatorPricePerHour = britishPounds(REAL_LIFE_LESSON_PRICE_HOUR)
  const hoursSpent = timeSpentSeconds / 60 / 60
  const academatorCost = academatorPricePerHour.multiply(hoursSpent)

  const moneySaved = realLifeCost.subtract(academatorCost.intValue)

  if (moneySaved.intValue < 0) {
    return britishPounds(0).format()
  }

  return moneySaved.format()
}
