import { atom, useAtom } from 'jotai'

const LOADING = 1
const LOADED = 5
const ENDED = 10

export const STATUSES = {
  LOADING,
  LOADED,
  ENDED,
}

const status = atom(LOADING)

const useAppStatus = () => useAtom(status)

export default useAppStatus
