import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const FooterLeftBlock = ({ isError, isSuccess }) => {
  const { t } = useTranslation('apps')

  const iconClass = classnames('text-3xl md:text-4xl lg:text-5xl', {
    'text-red-600': isError,
    'text-lime-600': isSuccess,
  })

  return (
    <div className={'flex items-center py-6 sm:py-0'}>
      <span className="fa-layers fa-fw text-6xl md:text-7xl lg:text-8xl">
        <FontAwesomeIcon icon={['fas', 'circle']} color="white" />

        {isError ? (
          <FontAwesomeIcon className={iconClass} icon={['fas', 'times']} />
        ) : (
          <FontAwesomeIcon className={iconClass} icon={['fas', 'check']} />
        )}
      </span>

      <div className={'flex flex-col ml-5 lg:ml-6'}>
        <div
          className={'font-bitter dark:text-white text-xl sm:text-2xl lg:text-3xl font-bold mb-1'}
        >
          {isError ? t('question.error') : t('question.success')}
        </div>

        <div className={'text-gray-600 dark:text-gray-300 font-light'}>{t('buttons.report')}</div>
      </div>
    </div>
  )
}

FooterLeftBlock.propTypes = {
  isError: PropTypes.bool,
  isSuccess: PropTypes.bool,
}

export default FooterLeftBlock
