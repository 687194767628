import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import useSound from 'use-sound'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Components
import Wrapper from '../components/Wrapper'
import Header from '../components/Header'
import Footer from '../components/Footer'
import FooterLeftBlock from '../components/Footer/FooterLeftBlock'
import Body from '../components/Body'
import HtmlContent from '../components/HtmlContent'
import AnswerBoxGroup from '../components/AnswerBoxGroup'
import { renderFullScreenModal } from '../../components/Modal/fabric'

// Utils
import useLocator from '../hooks/useLocator'
import Confetti from '../utils/confetti'
import questionCorrectMp3 from '../static/success2.mp3'
import questionWrongMp3 from '../static/failure2.mp3'
import useQuestionAnswers, { questionAnswersWorker } from '../hooks/useQuestionAnswers'
import useQuestionActiveAnswers from '../hooks/useQuestionActiveAnswers'

const Question = ({
  question,
  title,
  subtitle,
  answers,
  isExamQuestion,
  isExamEnded,
  isLastQuestion,
  onLastQuestionContinueClick,
}) => {
  const { t } = useTranslation('apps')

  const { goBack, goForward } = useLocator()

  const [playCorrectSound, { stop: stopCorrectSound }] = useSound(questionCorrectMp3)
  const [playWrongSound, { stop: stopWrongSound }] = useSound(questionWrongMp3)

  const {
    shuffledAnswers,
    correctAnswers,
    setRespondedAnswers,
    clearRespondedAnswers,
    wrongAnswers,
    isResponded,
    isWrong,
    isCorrect,
    shuffleAnswers,
  } = useQuestionAnswers(question.id, answers)

  const [activatedAnswers, activateAnswer, clearActiveAnswers, isActivated] =
    useQuestionActiveAnswers(question.id, correctAnswers.length)

  const onHelpClick = () => {
    const { help_content } = question

    renderFullScreenModal({
      html: (
        <Body>
          <HtmlContent id={question.id + '-help'} html={help_content} />
        </Body>
      ),
    })
  }

  const onTryAgainClick = () => {
    clearActiveAnswers()
    clearRespondedAnswers()
    shuffleAnswers()
  }

  const onRespondClick = (e) => {
    const { isCorrect: isCorrectActivated } = questionAnswersWorker(activatedAnswers, answers)

    if (isCorrectActivated) {
      playCorrectSound()
      Confetti.questionCannon(e.target)
    } else {
      playWrongSound()
    }

    setRespondedAnswers(activatedAnswers)
  }

  const onContinueClick = () => {
    if (isLastQuestion && onLastQuestionContinueClick) {
      onLastQuestionContinueClick()
    }

    goForward()
  }

  useEffect(() => {
    return () => {
      stopCorrectSound()
      stopWrongSound()
      Confetti.reset()
    }
  }, [stopCorrectSound, stopWrongSound])

  return (
    <Wrapper>
      <Header
        title={title}
        subtitle={subtitle}
        showTimer={isExamQuestion && isExamEnded === false}
        showCloseButton={isExamQuestion && isExamEnded === false}
        showGoBackButton={isExamQuestion === false || isExamEnded === true}
        goBack={goBack}
      />

      <Body mediumWide={true}>
        <HtmlContent
          id={question.id}
          proseFullWidth={true}
          audibleAutoplayFirst={true}
          katexFontSize={'LARGE'}
          html={question.content}
        />

        <AnswerBoxGroup
          showCorrectAnswer={isExamQuestion === true}
          isResponded={isResponded}
          isCorrect={isCorrect}
          onClick={(id) => activateAnswer(id)}
          activeAnswers={activatedAnswers}
          wrongAnswers={wrongAnswers}
          correctAnswers={correctAnswers}
          answers={shuffledAnswers}
        />
      </Body>

      <Footer
        isError={isWrong}
        isSuccess={isCorrect}
        left={() => {
          if (isResponded === false) {
            return null
          }

          return <FooterLeftBlock isSuccess={isCorrect} isError={isWrong} />
        }}
        right={() => {
          if (isResponded === false) {
            return (
              <button
                className={classnames('btn btn-lg w-full sm:w-auto', {
                  'btn-secondary': isActivated === false,
                  'btn-primary chameleon': isActivated === true,
                })}
                disabled={isActivated === false}
                onClick={onRespondClick}
              >
                {t('buttons.check-answer')}
              </button>
            )
          }

          if (isCorrect || isExamQuestion === true) {
            return (
              <button
                className={'btn btn-lg btn-primary w-full sm:w-auto'}
                onClick={onContinueClick}
              >
                {t('buttons.continue')}
              </button>
            )
          }

          return (
            <>
              <button
                className={classnames('btn btn-lg btn-light w-full sm:w-auto', {
                  'mr-2': true,
                  hidden: question.help_content === null,
                })}
                onClick={onHelpClick}
              >
                <FontAwesomeIcon
                  className={'inline-block mr-2 relative'}
                  icon={['fas', 'pennant']}
                />

                {t('buttons.help')}
              </button>

              <button
                className={classnames('btn btn-lg btn-primary w-full sm:w-auto')}
                onClick={onTryAgainClick}
              >
                <FontAwesomeIcon
                  className={'inline-block mr-2 relative'}
                  icon={['fas', 'repeat']}
                />

                {t('buttons.try-again')}
              </button>
            </>
          )
        }}
      />
    </Wrapper>
  )
}

Question.propTypes = {
  question: PropTypes.object.isRequired,
  answers: PropTypes.array.isRequired,

  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,

  isExamQuestion: PropTypes.bool.isRequired,
  isExamEnded: PropTypes.bool.isRequired,

  isLastQuestion: PropTypes.bool.isRequired,
  onLastQuestionContinueClick: PropTypes.func,
}

export default Question
