import useLocator from './useLocator'
import { TYPES } from '../utils/locations'
import Timers from '../utils/timer'

/**
 * Timer hook
 */
const useTimer = () => {
  const { allLocations } = useLocator()

  const getSecondsSpentOnExamQuestions = () => {
    return getSecondsSpentOnPages([TYPES.EXAM_QUESTION])
  }

  const getSecondsSpentOnLearning = () => {
    return getSecondsSpentOnPages([TYPES.LESSON, TYPES.THEORY, TYPES.QUESTION])
  }

  const getTotalSecondsSpent = () => {
    return getSecondsSpentOnExamQuestions() + getSecondsSpentOnLearning()
  }

  const getSecondsSpentOnPages = (types) => {
    const locations = allLocations.filter((loc) => types.indexOf(loc.type) !== -1)

    if (locations.length === 0) {
      return 0
    }

    return locations
      .map((loc) => Timers.getTimer(loc.href).getTimeInSeconds())
      .reduce((sec, sec2) => sec + sec2)
  }

  return {
    getSecondsSpentOnExamQuestions,
    getSecondsSpentOnLearning,
    getTotalSecondsSpent,
  }
}

export default useTimer
