import React from 'react'
import { Router } from '@reach/router'
import { useTranslation } from 'react-i18next'
import useSound from 'use-sound'

// Routes
import Route from '../routes/Route'
import Question from '../routes/Question'
import Exam from '../routes/Exam'
import Results from '../routes/Results'

// Utils
import useLocator from '../hooks/useLocator'
import useAppStatus, { STATUSES } from '../state/useAppStatus'
import Timers from '../utils/timer'
import { TYPES } from '../utils/locations'
import useResults from '../hooks/useResults'
import endOfTaskMp3 from '../static/end.mp3'
import Confetti from '../utils/confetti'
import useEffectOnce from '../../hooks/useEffectOnce'

const ExamApp = ({ answers, exam, examQuestions }) => {
  const { t } = useTranslation('apps')

  const { goFirstLocation, changeVisibility, startHistoryListener } = useLocator()
  const [appStatus, setAppStatus] = useAppStatus()

  const { isExamPassed } = useResults([], examQuestions, answers)

  const [playPassedSound, { stop: stopPassedSound }] = useSound(endOfTaskMp3)

  useEffectOnce(() => {
    goFirstLocation().then(() => {
      setAppStatus(STATUSES.LOADED)
    })

    const removeListener = startHistoryListener()

    return () => {
      removeListener()
      stopPassedSound()
      Confetti.reset()
    }
  })

  if (appStatus === STATUSES.LOADING) {
    return null
  }

  const isExamEnded = appStatus === STATUSES.ENDED

  return (
    <Router>
      <Exam
        path="/"
        exam={exam}
        onStartExam={() => changeVisibility([], [TYPES.EXAM])}
        isIndependentFromTheory={true}
        countQuestions={examQuestions.length}
        isEnded={isExamEnded}
      />

      <Route
        path="question/:questionId"
        content={(params) => (
          <Question
            question={examQuestions.find((x) => x.id === params.questionId)}
            title={
              t('header.question') +
              ' #' +
              ('0' + (examQuestions.map((x) => x.id).indexOf(params.questionId) + 1)).slice(-2)
            }
            subtitle={exam.name}
            isExamQuestion={true}
            isExamEnded={isExamEnded}
            isLastQuestion={examQuestions[examQuestions.length - 1].id === params.questionId}
            onLastQuestionContinueClick={() => {
              if (isExamEnded === false) {
                changeVisibility([TYPES.EXAM], [])
                Timers.setIsTimeTrackingEnabled(false)
                Timers.stopAllTimers()
                setAppStatus(STATUSES.ENDED)

                if (isExamPassed) {
                  playPassedSound()
                  Confetti.resultsFireworks()
                }
              }
            }}
            answers={answers.filter((x) => x.question_id === params.questionId)}
          />
        )}
      />

      <Results
        path={'results'}
        exam={exam}
        isExamOnly={true}
        questions={[]}
        examQuestions={examQuestions}
        title={exam.name}
        subtitle={t('examination.header')}
        answers={answers}
      />
    </Router>
  )
}

export default ExamApp
