import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const SecondsContainer = ({ seconds, classNames }) => {
  const timerShortStyle = {
    minWidth: '55px',
  }

  const timerLongStyle = {
    minWidth: '85px',
  }

  const date = new Date(seconds * 1000)

  let label = date.toISOString().substr(14, 5) // 00:00

  const isLarge = seconds >= 3600

  if (isLarge) {
    // for 1 hour + show: 00:00:00
    label = date.toISOString().substr(11, 8)
  }

  return (
    <div
      style={isLarge ? timerLongStyle : timerShortStyle}
      className={classnames('text-black dark:text-white', classNames)}
    >
      {label}
    </div>
  )
}

SecondsContainer.propTypes = {
  seconds: PropTypes.number,
  classNames: PropTypes.string,
}

export default SecondsContainer
