import i18n from '../../config/i18n'
import { getRandomElement } from '../../utils/array'

const t = i18n.getFixedT(null, 'apps')
const headers = t('results.header', { returnObjects: true })

const randomSuccessHeader = getRandomElement(headers.success)
const randomFailureHeader = getRandomElement(headers.failure)

export { randomSuccessHeader, randomFailureHeader }
