import React from 'react'
import { Router } from '@reach/router'
import LessonPage from './LessonPage'
import ExamPage from './ExamPage'
import GenerativeQuestionPage from './GenerativeQuestionPage'

const AppPage = () => {
  return (
    <Router className={'h-full'} basepath={'/app/'}>
      <LessonPage path="/lessons/:lessonId/*" />
      <ExamPage path="/exams/:examId/*" />
      <GenerativeQuestionPage path="/generate/:questionId/*" />
    </Router>
  )
}

export default AppPage
