const LESSON = 'lesson'
const THEORY = 'theory'
const QUESTION = 'question'
const EXAM = 'exam'
const EXAM_QUESTION = 'exam-question'
const RESULT = 'result'

export const TYPES = {
  LESSON,
  THEORY,
  RESULT,
  QUESTION,
  EXAM,
  EXAM_QUESTION,
}

export const getLessonLocations = (urlPrefix, lesson, theories, questions, exam, examQuestions) => {
  let paths = []

  paths.push({
    href: urlPrefix + lesson.id,
    type: LESSON,
    short: 'T' + 0,
    name: lesson.name,
    disabled: false,
    visible: true,
  })

  theories.forEach((theory, i) => {
    paths.push({
      href: urlPrefix + lesson.id + '/theory/' + theory.id,
      type: THEORY,
      short: 'T' + (i + 1),
      name: theory.name,
      disabled: true,
      visible: true,
    })
  })

  questions.forEach((question, i) => {
    paths.push({
      href: urlPrefix + lesson.id + '/question/' + question.id,
      type: QUESTION,
      short: 'Q' + (i + 1),
      questionOrder: i + 1,
      questionId: question.id,
      disabled: true,
      visible: true,
    })
  })

  paths.push({
    href: urlPrefix + lesson.id + '/exam/' + exam.id,
    type: EXAM,
    short: 'EX',
    disabled: true,
    visible: true,
  })

  examQuestions.forEach((question, i) => {
    paths.push({
      href: urlPrefix + lesson.id + '/exam/' + exam.id + '/question/' + question.id,
      type: EXAM_QUESTION,
      short: 'Q' + (i + 1),
      questionOrder: i + 1,
      questionId: question.id,
      disabled: true,
      visible: false,
    })
  })

  paths.push({
    href: urlPrefix + lesson.id + '/results',
    type: RESULT,
    short: null,
    disabled: true,
    visible: true,
  })

  return paths
}

export const getExamLocations = (urlPrefix, exam, examQuestions) => {
  let paths = []

  paths.push({
    href: urlPrefix + exam.id,
    type: EXAM,
    short: 'EX',
    disabled: false,
    visible: true,
  })

  examQuestions.forEach((question, i) => {
    paths.push({
      href: urlPrefix + exam.id + '/question/' + question.id,
      type: EXAM_QUESTION,
      short: 'Q' + (i + 1),
      questionOrder: i + 1,
      questionId: question.id,
      disabled: true,
      visible: true,
    })
  })

  paths.push({
    href: urlPrefix + exam.id + '/results',
    type: RESULT,
    short: null,
    disabled: true,
    visible: true,
  })

  return paths
}
