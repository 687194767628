import React from 'react'
import PropTypes from 'prop-types'

const Title = ({ children }) => {
  return <div className={'text-gray-800 dark:text-gray-200'}>{children}</div>
}

const Subtitle = ({ children }) => {
  return <div className={'text-gray-600 dark:text-gray-400 font-light'}>{children}</div>
}

const HeaderTitle = ({ title, subtitle, titleSubtitleReversed }) => {
  const titleComponent = <Title key={'title'}>{title}</Title>
  const subtitleComponent = <Subtitle key={'subtitle'}>{subtitle}</Subtitle>

  const labels =
    titleSubtitleReversed === true
      ? [subtitleComponent, titleComponent]
      : [titleComponent, subtitleComponent]

  return <div className={'flex flex-col'}>{labels}</div>
}

HeaderTitle.propTypes = {
  title: PropTypes.any,
  subtitle: PropTypes.any,
  titleSubtitleReversed: PropTypes.bool,
}

export default HeaderTitle
