import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const ProgressStep = ({ isActive, isDisabled, label, onClick, isLast, isVisible }) => {
  const clickEvent = isDisabled ? null : onClick

  return (
    <div
      onClick={clickEvent}
      onKeyDown={clickEvent}
      role="presentation"
      className={classnames('rounded-full w-7 h-7 font-bitter font-medium', {
        'bg-white text-black border border-gray-200 dark:bg-gray-900 dark:text-white dark:border-gray-600':
          isActive === false && isLast === false && isDisabled === true,
        'bg-black text-white progress-step-active dark:bg-white dark:text-black':
          isActive && isLast === false,
        'cursor-pointer': isDisabled === false,
        'bg-gray-200 text-black dark:bg-gray-600 dark:text-white':
          isActive === false && isDisabled === false && isLast === false,
        'bg-white dark:bg-gray-400 bg-finish-gray': isLast && isDisabled === true,
        'bg-white bg-finish-black progress-step-active': isLast && isDisabled === false,
        hidden: isVisible === false,
      })}
    >
      <div className={'w-full h-full flex items-center justify-center text-xs'}>{label}</div>
    </div>
  )
}

ProgressStep.propTypes = {
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLast: PropTypes.bool,
  isVisible: PropTypes.bool,
  label: PropTypes.any,
  onClick: PropTypes.func,
}

export default ProgressStep
