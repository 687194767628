import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import currency from 'currency.js'

export const britishPounds = (value) =>
  currency(value, { symbol: '£', separator: '.', decimal: ',', fromCents: true })

export const getLabel = (currencyVal) => {
  let label = currencyVal.format()

  if (currencyVal.cents() === 0) {
    label = label.slice(0, label.length - 3)
  }

  return label
}

const MoneyContainer = ({ cents, classNames }) => {
  const currencyVal = britishPounds(cents)

  const label = getLabel(currencyVal)

  return <div className={classnames('academator-money', classNames)}>{label}</div>
}

MoneyContainer.propTypes = {
  cents: PropTypes.number,
  classNames: PropTypes.any,
}

export default MoneyContainer
