import React, { useState } from 'react'
import useTimer from '../../hooks/useTimer'
import useEffectOnce from '../../../hooks/useEffectOnce'
import SecondsContainer from './SecondsContainer'

const Timer = ({ classNames }) => {
  const { getSecondsSpentOnExamQuestions } = useTimer()

  const [seconds, setSeconds] = useState(getSecondsSpentOnExamQuestions())

  const timeout = 100

  useEffectOnce(() => {
    const intervalID = setInterval(() => {
      setSeconds(getSecondsSpentOnExamQuestions())
    }, timeout)

    return () => clearInterval(intervalID)
  })

  return <SecondsContainer seconds={seconds} classNames={classNames} />
}

export default Timer
