import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

// Components
import SecondsContainer from '../../components/Timer/SecondsContainer'

const ResultsTableRow = ({ short, name, secondsSpent, isWrong, isCorrect }) => (
  <tr
    className={classnames({
      'bg-red-100 dark:bg-red-600': isWrong,
      'bg-lime-100 dark:bg-lime-600': isCorrect,
    })}
  >
    <th scope="row">
      <span className={'pl-4 font-bitter'}>{short}</span>
    </th>

    <td>{name}</td>

    <td className={'text-right'}>
      <SecondsContainer classNames={'pr-4'} seconds={secondsSpent} />
    </td>
  </tr>
)

ResultsTableRow.propTypes = {
  short: PropTypes.any,
  name: PropTypes.string,
  secondsSpent: PropTypes.number,
  isWrong: PropTypes.bool,
  isCorrect: PropTypes.bool,
}

export default ResultsTableRow
