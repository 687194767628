import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Trans } from 'react-i18next'

// Components
import Wrapper from '../components/Wrapper'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Body from '../components/Body'
import Emoji from '../../components/Emoji'
import HtmlContent from '../components/HtmlContent'
import SecondsContainer from '../components/Timer/SecondsContainer'
import ResultsTable from './Results/ResultsTable'

// Utils
import { TYPES } from '../utils/locations'
import useLocator from '../hooks/useLocator'
import { randomSuccessHeader, randomFailureHeader } from '../utils/random'
import { getMinutesSaved, getMoneySaved } from '../utils/savings'
import useTimer from '../hooks/useTimer'
import useResults from '../hooks/useResults'

const Results = ({ title, subtitle, answers, questions, examQuestions, isExamOnly }) => {
  const { t } = useTranslation('apps')

  const { goBack, allLocations, getSecondsSpentOnLocation, redirectHome } = useLocator()

  const { isExamPassed, countCorrectExamQuestions, countTotalExamQuestions } = useResults(
    questions,
    examQuestions,
    answers,
  )

  const { getSecondsSpentOnExamQuestions, getSecondsSpentOnLearning, getTotalSecondsSpent } =
    useTimer()

  const examLocations = allLocations.filter((loc) => loc.type === TYPES.EXAM_QUESTION)

  const theoryLocations =
    isExamOnly === false
      ? allLocations.filter(
          (loc) => [TYPES.LESSON, TYPES.THEORY, TYPES.QUESTION].indexOf(loc.type) !== -1,
        )
      : []

  const { emoji: failureEmoji, label: failureLabel } = randomFailureHeader
  const { emoji: successEmoji, label: successLabel } = randomSuccessHeader

  return (
    <Wrapper>
      <Header
        title={title}
        subtitle={subtitle}
        titleSubtitleReversed={isExamOnly === false}
        goBack={goBack}
      />

      <Body>
        <HtmlContent id={'results'}>
          <h1>
            <Emoji
              classNames={'text-5xl mr-3'}
              emoji={isExamPassed ? successEmoji : failureEmoji}
            />

            {isExamPassed ? successLabel : failureLabel}
          </h1>

          <p>
            {isExamPassed
              ? t('results.success')
              : t('results.failure', {
                  count: countCorrectExamQuestions,
                  total: countTotalExamQuestions,
                })}
          </p>

          {isExamOnly === false && (
            <ResultsTable
              isTheory={true}
              getSecondsSpentOnLocation={getSecondsSpentOnLocation}
              locations={theoryLocations}
              headerContent={
                <div className={'text-xl font-bold font-bitter'}>{t('results.theory-header')}</div>
              }
              answers={answers}
            >
              <div className={'text-right pr-4 text-xl font-medium'}>
                <SecondsContainer seconds={getSecondsSpentOnLearning()} />
              </div>
            </ResultsTable>
          )}

          <ResultsTable
            isTheory={false}
            getSecondsSpentOnLocation={getSecondsSpentOnLocation}
            locations={examLocations}
            headerContent={
              <div className={'text-xl font-bold font-bitter'}>{t('results.results-header')}</div>
            }
            answers={answers}
          >
            <div className={'text-right pr-4 text-xl font-medium'}>
              <SecondsContainer seconds={getSecondsSpentOnExamQuestions()} />
            </div>
          </ResultsTable>

          {isExamPassed && isExamOnly === false && (
            <p>
              <Trans i18nKey={'results.savings-message'} t={t}>
                In completing this lesson you have saved{' '}
                <b className={'text-2xl'}>{{ money: getMoneySaved(getTotalSecondsSpent()) }}</b> and
                up to{' '}
                <b className={'text-2xl'}>{{ minutes: getMinutesSaved(getTotalSecondsSpent()) }}</b>{' '}
                minutes of your precious time!
              </Trans>
            </p>
          )}
        </HtmlContent>
      </Body>

      <Footer
        right={() => {
          if (isExamOnly) {
            return (
              <button
                className={'btn btn-lg btn-primary chameleon w-full sm:w-auto'}
                onClick={redirectHome}
              >
                {t('buttons.close-exam')}
              </button>
            )
          }

          return (
            <button
              className={'btn btn-lg btn-primary chameleon w-full sm:w-auto'}
              onClick={redirectHome}
            >
              {t('buttons.close-lesson')}
            </button>
          )
        }}
      />
    </Wrapper>
  )
}

Results.propTypes = {
  answers: PropTypes.array.isRequired,
  questions: PropTypes.array.isRequired,
  examQuestions: PropTypes.array.isRequired,
  isExamOnly: PropTypes.bool.isRequired,

  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
}

export default Results
