import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { NakedLayout, SplashPage, Redirect } from '../../../components/Layout'
import LessonApp from '../../../app/LessonApp'
import api from '../../../apis'
import ROUTES from '../../../config/routes'
import { notificationWarning } from '../../../utils/notification'

const LessonPage = ({ lessonId }) => {
  const [responseCode, setResponseCode] = useState(null)
  const [responseData, setResponseData] = useState(false)

  useEffect(() => {
    api.lesson
      .getLessonData(lessonId)
      .then((response) => {
        setResponseCode(200)
        setResponseData(response.data)
      })
      .catch((error) => {
        setResponseCode(error.response.status)
        setResponseData(error.response.data)
        return Promise.resolve(error)
      })
  }, [lessonId])

  if (responseData === false || responseCode === null) {
    return <SplashPage />
  }

  if (responseCode === 404 || responseCode === 403) {
    return <SplashPage errorMessage={responseData.message} />
  }

  if (responseCode === 401) {
    notificationWarning(responseData.message, 1500)
    return <Redirect to={ROUTES.signin} />
  }

  return (
    <NakedLayout useDarkMode>
      <LessonApp urlPrefix={'/app/lessons/'} {...responseData} />
    </NakedLayout>
  )
}

LessonPage.propTypes = {
  lessonId: PropTypes.string,
}

export default LessonPage
